import * as pkg from '~/package.json';
import { UAParser } from 'ua-parser-js';

export function initSentry(Sentry, app, router, nuxtApp) {
  const {
    public: { sentry },
  } = useRuntimeConfig();

  if (sentry.environment !== 'production') {
    console.log('Release:', `${pkg.name}@${pkg.version}`, sentry.environment);
    console.log('DSN:', sentry.dsn || 'No dsn link provided');
    console.log('Environment:', sentry.environment || 'No sentry env provided');
  }

  const parser = new UAParser();
  parser.setUA(navigator.userAgent);
  const result = parser.getResult();
  const browser = result.browser;

  const isOld =
    (browser.name === 'Safari' && browser.major <= 14) ||
    (browser.name === 'Mobile Safari' && browser.major <= 14);

  if (isOld) return;

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    release: `${pkg.name}@${pkg.version}`,
    environment: sentry.environment,

    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        blockAllMedia: false,
        useCompression: false,
        maskAllInputs: false,
        maskAllText: false,
        networkDetailAllowUrls: [
          'localhost:3080',
          /^http:\/\/localhost:3080\//,
          /^https:\/\/api-lk\.prostoyvopros\.ru\//,
          /^https:\/\/api-lkdev\.prostoyvopros\.ru\//,
          /^https:\/\/api-chat\.bytepass\.ru\//,
        ],
      }),
    ],

    tracePropagationTargets: [
      'localhost:3080',
      /^https:\/\/api-lk\.prostoyvopros\.ru\//,
      /^https:\/\/api-lkdev\.prostoyvopros\.ru\//,
      /^https:\/\/api-chat\.bytepass\.ru\//,
    ],
    tracesSampleRate: 1,

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    debug: false,

    beforeSend(event, hint) {
      try {
        const dataForLong = useDataForLongStore();

        if (dataForLong.step_1 && Object.keys(dataForLong.step_1).length > 0) {
          const username = [
            dataForLong.step_1.mobile_phone,
            dataForLong.step_1.last_name,
            dataForLong.step_1.first_name,
            dataForLong.step_1.secondary_name,
          ]
            .filter((i) => i !== undefined && i !== '')
            .join(' ');

          const pageqs = useCookie('pageqs')?.value;

          const id = pageqs
            ? pageqs.click_id ||
              pageqs.clickId ||
              pageqs.click ||
              pageqs.utm_term ||
              pageqs.transaction_id ||
              ''
            : '';
          Sentry.setUser({ username, id });
        }

        if (event.exception) {
          console.error(`[sentry]: (${hint.originalException})`, { event, hint });
        }
        return event;
      } catch (err) {
        console.log(err);
      }
    },
  });
}
